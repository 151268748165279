import React from 'react'
import { useState } from 'react'

import { Header } from '../../components/header/Header'
import { Spinner } from '../../components/Spinner'
import { VercelButton } from '../TeamPage/components/VercelButton'

import { createProject } from '../../services/createProject'

export const CreateProjectPage = () => {
  const [projectName, setProjectName] = useState()
  const [inputError, setInputError] = useState('')
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const handleNameChange = (e) => {
    const result = e.target.value.toLowerCase()
    setProjectName(result)
  }

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (projectName === undefined) {
      setInputError('Project must have a name.')
      setLoading(false)
      console.log('error')
    }
    if (projectName.search(/[a-z]+$/)) {
      console.log(projectName)
      setInputError('Name must begin with a letter.')
      setLoading(false)
    }
    else {
      createProject(projectName).then((response) => {
        console.log(response)
        setInputError('')
        setLoading(false)
        setCompleted(true)
      }).catch((error) => {
        console.log(error)
        setLoading(false)
      })
    }
  }

  return (
    <div className="flex flex-col bg-black h-full w-screen text-white">
      <Header />
      <div className="flex flex-col bg-black items-center mt-40">
        <div className="flex flex-col bg-black mt-10 w-11/12 md:w-[500px]">
          <div className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-center items-center">
            <form className="space-y-3 w-5/6 flex flex-col" onSubmit={submit}>
              <div className="flex justify-center items-center mb-3">
                {!loading && !completed && (
                  <h2 className="font-bold font-poiret-one text-3xl">Name</h2>
                )}
              </div>
              {!loading && !completed && (
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center py-4">
                    <div className="p-2 focus:outline-none focus:ring">
                      <svg
                        xmlns="#"
                        id="Outlined"
                        width="32px"
                        height="32px"
                        viewBox="0 0 40 32"
                        className="fill-slate-500"
                      >
                        <g id="Fill">
                          <path d="M16,15a6,6,0,1,0-6-6A6,6,0,0,0,16,15ZM16,5a4,4,0,1,1-4,4A4,4,0,0,1,16,5Z" />
                          <path d="M24,17H8a5,5,0,0,0-5,5v7H5V22a3,3,0,0,1,3-3H24a3,3,0,0,1,3,3v7h2V22A5,5,0,0,0,24,17Z" />
                        </g>
                      </svg>
                    </div>
                  </span>
                  <input
                    type="text"
                    name="email"
                    onChange={handleNameChange}
                    placeholder="Enter project name"
                    className="w-full py-2 pl-10 text-base text-black rounded-md focus:outline-none border-2"
                  />
                </div>
              )}
              <div className="flex justify-center items-center">
                <h2 className="text-x1 text-red-500 font-bold">{inputError}</h2>
              </div>
              <div className="flex flex-col justify-center items-center">
                {!loading && !completed && (
                  <button className="w-full bg-transparent hover:bg-slate-800 font-bold font-poiret-one text-2xl text-amber-400 py-2 px-4 border border-slate-600 rounded mt-10">
                    Create Project
                  </button>
                )}
                {loading && !completed && (
                  <div>
                    <div className="text-center text-2xl text-white mb-5">
                      Creating project...
                    </div>
                    <div className="flex justify-center items-center w-full bg-transparent font-bold font-poiret-one text-2xl text-amber-400 py-2 px-4 border border-slate-600 rounded mt-10">
                      <div className="w-8 h-8">
                        <Spinner />
                      </div>
                    </div>
                  </div>
                )}
                {/* {!loading && completed && (
                  <div className='flex flex-col items-center justify-center'>
                    Project {projectName} completed!
                    
                  </div>
                )} */}
                {!loading && completed && (
                  <div className="flex flex-col items-center justify-center">
                    <div className="text-center text-2xl text-white mb-5">
                      Project{' '}
                      <span className="uppercase font-bold font-poiret-one text-3xl text-amber-400">
                        {projectName}
                      </span>{' '}
                      completed!
                    </div>
                    <VercelButton
                      link={`https://vercel.com/ventanas95/${projectName}/settings/git`}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
