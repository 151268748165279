import React from 'react'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { app } from '../../firebase-config'
import db from '../../firebase-config'
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { collection, getDocs } from '@firebase/firestore'

import { Spinner } from '../../components/Spinner'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const tokenCollectionRef = collection(db, 'tokens')

  const navigate = useNavigate()

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    // console.log('Email:' , e.target.email.value)
    // console.log('Password:' , e.target.password.value)
    const authentication = getAuth()

    setPersistence(authentication, browserLocalPersistence)
      .then(() => signInWithEmailAndPassword(authentication, email, password))
      .then((response) => {
        localStorage.setItem('token', response._tokenResponse.refreshToken)
        getApiTokens()
      })
      .catch((error) => {
        console.log(error.code)
        setLoading(false)
        if (error.code === 'auth/wrong-password') {
          setErrorMessage('Incorrect email or password')
        }
        if (error.code === 'auth/user-not-found') {
          setErrorMessage('Incorrect email or password')
        }
        if (error.code === 'auth/invalid-email') {
          setErrorMessage('Incorrect email or password')
        }
      })
  }

  const getApiTokens = async () => {
    const data = await getDocs(tokenCollectionRef)
    const vercelToken = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))[0].vercel_token
    const storyblokToken = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))[0].storyblok_token
    localStorage.setItem('Vercel Token', vercelToken)
    localStorage.setItem('Storyblok Token', storyblokToken)
    // console.log(localStorage.getItem('Vercel Token'))
    if (localStorage.getItem('Vercel Token') === 'undefined') {
      console.log('Failed to load API Tokens.')
    } else {
      setLoading(false)
      navigate('/teams')
    }
  }

  console.log(errorMessage)

  useEffect(() => {
    const authToken = localStorage.getItem('token')
    if (authToken) {
      navigate('/teams')
    }
  }, [])

  return (
    <div className="flex bg-black">
      <div className="flex flex-col h-screen p-3 bg-black shadow md:w-1/3 md:shrink-0 w-screen text-white border-2 border-slate-600 justify-center items-center">
        <img className="mb-5 ml-4" src="https://www.ventanas95.com/logo.svg" />
        <h2 className="mb-5 text-4xl font-bold font-poiret-one">
          ADMINISTRATION
        </h2>
        <form className="space-y-3 w-5/6" onSubmit={handleLogin}>
          <div className="flex justify-center items-center">
            <h2 className="text-x1 text-red-500 font-bold">{errorMessage}</h2>
          </div>
          <div className="flex justify-center items-center">
            <h2 className="text-x1 font-bold">Email Address</h2>
          </div>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center py-4">
              <div className="p-2 focus:outline-none focus:ring">
                <svg
                  xmlns="#"
                  id="Outlined"
                  width="32px"
                  height="32px"
                  viewBox="0 0 40 32"
                  className="fill-slate-500"
                >
                  <g id="Fill">
                    <path d="M16,15a6,6,0,1,0-6-6A6,6,0,0,0,16,15ZM16,5a4,4,0,1,1-4,4A4,4,0,0,1,16,5Z" />
                    <path d="M24,17H8a5,5,0,0,0-5,5v7H5V22a3,3,0,0,1,3-3H24a3,3,0,0,1,3,3v7h2V22A5,5,0,0,0,24,17Z" />
                  </g>
                </svg>
              </div>
            </span>
            <input
              type="text"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="example@gmail.com"
              className="w-full py-2 pl-10 text-base text-black rounded-md focus:outline-none border-2"
            />
          </div>
          <div className="flex justify-center items-center">
            <h2 className="text-x1 font-bold">Password</h2>
          </div>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center py-4">
              <div className="p-2 focus:outline-none focus:ring">
                <svg
                  xmlns="#"
                  id="Outlined"
                  width="32px"
                  height="32px"
                  viewBox="0 0 40 32"
                  className="fill-slate-500"
                >
                  <path d="M16,13a3,3,0,1,0,3,3A3,3,0,0,0,16,13Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,16,17Z" />
                  <path d="M24,13a3,3,0,1,0,3,3A3,3,0,0,0,24,13Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,24,17Z" />
                  <path d="M8,13a3,3,0,1,0,3,3A3,3,0,0,0,8,13Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,8,17Z" />
                </svg>
              </div>
            </span>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="8+ characters required"
              className="w-full py-2 pl-10 text-base text-black rounded-md focus:outline-none border-2"
            />
          </div>
          <div className="flex justify-center items-center">
            {!loading && (
              <button className="w-full bg-transparent hover:bg-slate-800 text-white font-semibold hover:text-white py-2 px-4 border border-slate-600 rounded mt-10">
                LOGIN
              </button>
            )}
            {loading && (
              <div className="flex justify-center items-center w-full bg-transparent text-white font-semibold hover:text-white py-2 px-4 border border-slate-600 rounded mt-10">
                <div className="w-6 h-6">
                  <Spinner />
                </div>
              </div>
            )}
          </div>
          {/* {!loading && <Spinner />} */}
        </form>
      </div>
      <div className="container mx-auto hidden md:block h-screen">
        <video
          className="object-cover h-screen"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="\images\Ventanas95Video.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
