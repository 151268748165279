import React from 'react'

export const VercelLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-14 w-14 mb-2 fill-black"
      viewBox="0 0 1155 1000"
    >
      <path d="M577.344 0L1154.69 1000H0L577.344 0Z" fill="currentFill" />
    </svg>
  )
}
