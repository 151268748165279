import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import formatDistance from 'date-fns/formatDistance'

import { Header } from '../../components/header/Header'
import { Spinner } from '../../components/Spinner'
import { DeploySpinner } from './components/DeploySpinner'
import { DeployCheckIcon } from './components/DeployCheckIcon'
import { DeployCanceledIcon } from './components/DeployCanceledIcon'
import { Ventanas95logo } from '../../components/Ventanas95logo'
import { VercelButton } from './components/VercelButton'
import { SiteLinkButton } from './components/SiteLinkButton'

import { getTeamProjects } from '../../services/getTeamProjects'
import { getTeam } from '../../services/getTeam'
import { cancelDeployment } from '../../services/cancelDeployment'

export const TeamPage = () => {
  let { id } = useParams()
  const [vercelTeamProjects, setVercelTeamProjects] = useState()
  const [team, setTeam] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [cancelStatus, setCancelStatus] = useState('CANCEL')
  const navigate = useNavigate()

  useEffect(() => {
    const authToken = localStorage.getItem('token')
    if (!authToken) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    getTeamProjects({ id })
      .then((response) => {
        setVercelTeamProjects(response)
        setIsLoading(false)
      })
      .catch((response) => {
        console.error(response)
        setIsLoading(false)
      })
  }, [id])

  useEffect(() => {
    getTeam({ id })
      .then((response) => {
        setTeam(response)
      })
      .catch((response) => {
        console.error(response)
      })
  }, [])

  console.log(vercelTeamProjects?.projects)
  // console.log(team)

  return (
    <div className="flex flex-col bg-black h-full w-screen text-white">
      <Header />
      <div className="flex flex-col bg-black h-full items-center mt-40">
        <div className="flex flex-col bg-black mt-10 w-4/5 md:w-[600px] lg:w-full">
          {!isLoading && vercelTeamProjects && (
            <div className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-center">
              {vercelTeamProjects?.projects.map((project) => {
                const projectName = project.name
                const liveUrl = project?.targets?.production?.alias[0]
                const deployStatus = project?.latestDeployments?.[0]?.readyState
                const latestCommitMessage =
                  project?.latestDeployments?.[0]?.meta?.githubCommitMessage
                const latestCommitRef =
                  project?.latestDeployments?.[0]?.meta?.githubCommitRef
                const latestCommmitAuthor =
                  project?.latestDeployments?.[0]?.meta?.githubCommitAuthorName
                const deployId = project?.latestDeployments?.[0]?.id
                let unixDeployDate = project?.latestDeployments?.[0]?.createdAt
                let deployDate = new Intl.DateTimeFormat('en-Us', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }).format(unixDeployDate)
                let newDate = formatDistance(new Date(deployDate), new Date(), {
                  addSuffix: true,
                })

                return (
                  <div
                    key={projectName}
                    className="mb-4 flex flex-col items-center lg:m-8 lg:flex-wrap"
                  >
                    <div className="uppercase flex flex-row items-center justify-center bg-black rounded p2 m-5 mb-0 h-10 w-auto font-bold text-5xl text-white ">
                      <div className="flex sm:flex-row flex-col items-center font-poiret-one text-amber-400">
                        <div className="w-7"></div>
                        <div className="self-center mx-2">{projectName}</div>
                        {liveUrl && (
                          <div className="h-7 w-7 mt-2 mb-10 sm:mb-0 sm:mt-0">
                            <SiteLinkButton link={`https://${liveUrl}`} />
                          </div>
                        )}
                        {!liveUrl && <div className="h-7 w-7 mt-2 sm:mt-0" />}
                      </div>
                    </div>
                    <div className="p-4 mb-2 flex flex-col items-center mt-5 sm:mt-0">
                      {team && (
                        <VercelButton
                          link={`https://www.vercel.com/${team.slug}/${project.name}`}
                        />
                      )}
                      {!team && <Spinner />}
                      {project.link.deployHooks[0] && (
                        <div className="uppercase font-bold text-4xl font-poiret-one lg:text-xl mt-2 lg:mt-4">
                          Deployment
                        </div>
                      )}
                      {project.link.deployHooks.map((deploy) => {
                        return (
                          <button
                            key={deploy.name}
                            type="button"
                            className="uppercase bg-black hover:bg-slate-800 text-white font-bold hover:text-white border-2 border-slate-600 hover:border hover:border-slate-500 py-2 px-2 m-5 h-16 w-36 rounded"
                            onClick={() => {
                              window.open(deploy.url, '_blank')
                            }}
                          >
                            <div>{deploy.name}</div>
                          </button>
                        )
                      })}
                      {deployStatus && (
                        <div className="p-4 mb-2 flex flex-col items-center">
                          <div className="uppercase font-bold text-3xl font-poiret-one lg:mt-4 mb-2 text-center">
                            Deployment Status
                          </div>
                          <div className="text-xl mb-2">
                            {deployStatus === 'READY' && (
                              <div className="text-green-500 flex flex-row items-center">
                                {deployStatus} <DeployCheckIcon />
                              </div>
                            )}
                            {deployStatus === 'QUEUED' && (
                              <div className="text-orange-500 flex flex-row items-center">
                                {deployStatus} <DeploySpinner />
                              </div>
                            )}
                            {deployStatus === 'BUILDING' && (
                              <div className="flex flex-col items-center">
                                <div className="text-orange-500 flex flex-row items-center">
                                  {deployStatus} <DeploySpinner />
                                </div>
                                <button
                                  className="uppercase bg-black hover:bg-slate-800 text-red-600 font-bold hover:text-white border-2 border-slate-600 hover:border hover:border-slate-500 py-2 px-2 m-5 h-16 w-36 rounded"
                                  onClick={async () => {
                                    const cancelResponse =
                                      await cancelDeployment(deployId, { id })
                                    if (cancelResponse.status === 200) {
                                      setCancelStatus('Canceled!')
                                    }
                                  }}
                                >
                                  {cancelStatus}
                                </button>
                              </div>
                            )}
                            {deployStatus === 'CANCELED' && (
                              <div className="text-red-500 flex flex-row items-center">
                                {deployStatus} <DeployCanceledIcon />
                              </div>
                            )}
                            {deployStatus === 'ERROR' && (
                              <div className="text-red-500 flex flex-row items-center">
                                {deployStatus} <DeployCanceledIcon />
                              </div>
                            )}
                          </div>
                          <div className="text-sm sm:text-lg text-center max-w-sm text-wrap">
                            <span className="font-bold text-amber-400">
                              {latestCommitMessage}
                            </span>{' '}
                            was deployed to{' '}
                            <span className="uppercase font-bold">
                              {latestCommitRef}
                            </span>{' '}
                            {newDate} by {latestCommmitAuthor}.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          {isLoading && (
            <div className="w-14 h-14 mt-50 self-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {!isLoading && (
        <div className="w-full h-10 sm:hidden flex justify-center mb-3 mt-5">
          <Ventanas95logo />
        </div>
      )}
      {isLoading && <> </>}
    </div>
  )
}
