import React from 'react'
import { VercelLogo } from './VercelLogo'

export const VercelButton = ({ link }) => {
  return (
    <div
      className="h-20 w-20 rounded-full border-2 border-black bg-white hover:invert flex flex-col justify-center items-center hover:cursor-pointer"
      onClick={() => {
        window.open(link)
      }}
    >
      <VercelLogo />
    </div>
  )
}
