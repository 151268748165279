import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAHQYkCHw0WJzycHywJzmLLb9skDHt__jY",
    authDomain: "admin.v95.se",
    projectId: "ventanas95dev",
    storageBucket: "ventanas95dev.appspot.com",
    messagingSenderId: "772546793790",
    appId: "1:772546793790:web:e62364b193bc760eda1c6f",
    measurementId: "G-Y65EPLGBGZ"
  };

export const app = initializeApp(firebaseConfig)
export default getFirestore()