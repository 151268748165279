import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { LoginPage } from './views/Login/LoginPage';
import { TeamList } from './views/TeamList/TeamList';
import { TeamPage } from './views/TeamPage/TeamPage';
import { CreateProjectPage } from './views/createProjectPage/CreateProjectPage';

function App() {
  return (
    <Router>
      <main className='bg-black h-full'>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/teams' element={<TeamList />} />
          <Route path='/customerstatus/:id' element={<TeamPage />} />
          <Route path='/create' element={<CreateProjectPage />}/>
        </Routes>
      </main>
    </Router>
  )
}

export default App