import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { HeaderButton } from './HeaderButton'
import { LogOutIcon } from './LogOutIcon'
import { TeamsIcon } from './TeamsIcon'
import { CreateIcon } from './CreateIcon'

export const Header = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/')
  }

  return (
    <div className="w-full h-40 overflow-hidden absolute flex items-center shrink 0">
      <img
        src="\images\Ventanas95Header.png"
        className="h-full w-full object-cover static overflow-hidden absolute"
      />
      <div className="w-full relative flex flex-row align-center justify-center sm:justify-between sm:items-center">
        <div className="flex sm:flex-row">
          <HeaderButton onClick={handleLogout}>
            <LogOutIcon />
            <div className="hidden sm:block">LOG OUT</div>
          </HeaderButton>
          <Link to="/teams">
            <HeaderButton>
              <TeamsIcon />
              <div className="hidden sm:block">TEAMS</div>
            </HeaderButton>
          </Link>
          <Link to="/create">
            <HeaderButton>
              <CreateIcon />
              <div className="hidden sm:block">CREATE</div>
            </HeaderButton>
          </Link>
        </div>
        <img
          src="https://www.ventanas95.com/logo.svg"
          className="h-40 w-40 static hidden sm:block"
        />
      </div>
    </div>
  )
}
