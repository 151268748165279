import React from 'react'

export const HeaderButton = ({ text, onClick, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="bg-black bg-opacity-75 hover:bg-slate-800 text-white font-semibold hover:text-amber-400 hover:border hover:border-slate-500 py-2 px-2 m-2 sm:m-4 sm:h-16 sm:w-36 h-16 w-20 rounded fill-white hover:fill-amber-400"
    >
      {children}
    </button>
  )
}
