import React, { useEffect, useState } from 'react'

import { Header } from '../../components/header/Header'
import { Spinner } from '../../components/Spinner'
import { Ventanas95logo } from '../../components/Ventanas95logo'
import { TeamCard } from './components/TeamCard'

import { getAllTeams } from '../../services/getAllTeams'
import { useNavigate } from 'react-router'

export const TeamList = () => {
  const [vercelTeams, setVercelTeams] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const authToken = localStorage.getItem('token')
    if (!authToken) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    getAllTeams()
      .then((response) => {
        setVercelTeams(response)
        setIsLoading(false)
      })
      .catch((response) => {
        console.error(response)
        setIsLoading(false)
      })
  }, [])

  // console.log(vercelTeams?.teams)

  return (
    <div className="flex flex-col bg-black h-full w-screen text-white">
      <Header />
      <div className="flex flex-col bg-black items-center mt-40">
        <div className="flex flex-col items-center mt-10 w-4/5 md:w-[500px]">
          <div>
            {!isLoading && vercelTeams && (
              <div>
                {vercelTeams?.teams.map((team) => {
                  return (
                    <TeamCard
                      key={team.id}
                      team={team}
                      className="flex flex-col border-2 border-slate-600 rounded"
                    ></TeamCard>
                  )
                })}
              </div>
            )}
            {isLoading && (
              <div className="w-14 h-14">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLoading && (
        <div className="w-full h-10 sm:hidden flex justify-center mb-3 mt-5">
          <Ventanas95logo />
        </div>
      )}
      {isLoading && <> </>}
    </div>
  )
}
