import React from 'react'
import { useNavigate } from 'react-router-dom'

export const TeamCard = ({ team }) => {
  const navigate = useNavigate()

  return (
    <div
      team={team}
      className="mb-4 flex flex-col border-2 border-slate-600 rounded hover:bg-slate-800 hover:cursor-pointer"
      onClick={() => navigate(`/customerstatus/${team.id}`)}
    >
      <div className="p-4 m-2 flex justify-center">
        <h1 className="font-bold font-poiret-one text-2xl text-amber-400 uppercase">
          {team.name}
        </h1>
      </div>
    </div>
  )
}
