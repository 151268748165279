import React from 'react'

export const DeployCheckIcon = () => {
  return (
    <div role="status" className="flex justify-center ml-2">
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        preserveAspectRatio="xMidYMid meet"
      >
        <circle cx="32" cy="32" r="30" fill="#4bd37b" />
        <path fill="#fff" d="M46 14L25 35.6l-7-7.2l-7 7.2L25 50l28-28.8z" />
      </svg>
    </div>
  )
}
